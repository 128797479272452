import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { SnackbarService } from '../../../../services/snackbar.service';
import {
  LinkItem,
  VoucherApiService,
} from '../../services/voucher-api.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-voucher-code-import',
  templateUrl: './voucher-code-import.component.html',
  styleUrls: ['./voucher-code-import.component.scss'],
})
export class VoucherCodeImportComponent {
  readonly requiredHeaders = [
    'PO Nr.',
    'Pos Nr.',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'Activation Code',
    'Type',
    'S/N',
  ];

  readonly allowedMaterialNumbers = [
    '7955034',
    '6135792',
    '7968153',
    '7377959',
    '7955033',
    '6135791',
    '7949932',
    '7377958',
  ];

  fileName = '';

  constructor(
    private voucherService: VoucherApiService,
    private snackBarService: SnackbarService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  handleFileInput(files: FileList | null) {
    if (files && files.length > 0) {
      const file: File = <File>files.item(0);
      this.fileName = file.name;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.handleFileReaderEvent(<string>fileReader.result);
      };

      fileReader.readAsText(file);
    }
  }

  private handleFileReaderEvent(fileContent: string): void {
    const linkItems = this.csvToJSON(fileContent);
    if (linkItems.length > 0 && this.validateVoucherLinkItems(linkItems)) {
      this.linkVouchersWithSerial(linkItems);
    } else {
      this.openErrorDialog();
    }
  }

  private openErrorDialog(invalidVoucher?: boolean): void {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        fileName: this.fileName,
        invalidVoucher: invalidVoucher,
      },
    });
  }

  private linkVouchersWithSerial(linkItems: CsvItem[]): void {
    const chunkObservables = this.getChunks(linkItems).map((chunk) => {
      return this.voucherService.linkVouchersWithSerialNumber(chunk);
    });

    forkJoin(chunkObservables).subscribe(
      () =>
        // Vouchers successfully linked
        this.snackBarService.showSnackbar(
          this.translateService.instant('snackbar.success.serialLink'),
          true
        ),
      (error) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          this.openErrorDialog(true);
        } else {
          // General error. Please try again later.
          this.snackBarService.showSnackbar(
            this.translateService.instant('snackbar.error.serialLink'),
            false
          );
        }
      }
    );
  }

  private getChunks(linkItems: CsvItem[]): LinkItem[][] {
    const mappedItems: LinkItem[] = linkItems.map((item) => {
      return {
        code: item['Activation Code'],
        serialNr: item['S/N'],
      };
    });

    const chunkList: LinkItem[][] = [];
    const chunkSize = 1000;
    for (let offset = 0; offset < linkItems.length; offset += chunkSize) {
      chunkList.push(mappedItems.slice(offset, offset + chunkSize));
    }
    return chunkList;
  }

  private csvToJSON(csv: string): CsvItem[] {
    const lines: string[] = csv.replace(/\r+/g, '').split('\n').filter(Boolean);
    const csvItems: CsvItem[] = [];

    const headers: string[] = this.getHeaders(lines[0]);
    const content: string[] = lines.splice(1);

    if (!this.validateHeaders(headers)) {
      return [];
    }

    content.forEach((line) => {
      const voucherLinkItem: CsvItem = <CsvItem>{};
      const currentLine = line.split(',').filter(Boolean);

      headers.forEach((header, i) => {
        voucherLinkItem[<keyof CsvItem>header] = currentLine[i]?.replace(
          /"+/g,
          ''
        );
      });
      csvItems.push(voucherLinkItem);
    });

    return csvItems;
  }

  private getHeaders(firstLine: string): string[] {
    if (!firstLine) {
      return [];
    }
    const headers: string[] = [];
    firstLine
      .split(',')
      .forEach((header) => headers.push(header.replace(/"+/g, '')));
    return headers;
  }

  private validateHeaders(headers: string[]): boolean {
    return (
      this.requiredHeaders.length === headers.length &&
      this.requiredHeaders.every((header, index) => {
        return header === headers[index];
      })
    );
  }

  private validateVoucherLinkItems(linkItems: CsvItem[]): boolean {
    let csvIsValid = true;
    if (linkItems.length === 0) {
      csvIsValid = false;
      return csvIsValid;
    }

    for (const item of linkItems) {
      if (!this.allowedMaterialNumbers.includes(item.Type)) {
        csvIsValid = false;
        break;
      }
      for (const value of Object.values(item)) {
        if (typeof value === undefined || !value || !csvIsValid) {
          csvIsValid = false;
          break;
        }
      }
    }

    return csvIsValid;
  }
}

interface CsvItem {
  'PO Nr.': string;
  'Pos Nr.': string;
  'Activation Code': string;
  Type: string;
  'S/N': string;
}
