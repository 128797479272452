import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VoucherApiService {
  constructor(private httpClient: HttpClient) {}

  // Modulation
  readonly VOUCHERS_01_MATERIAL = [7955034, 6135792, 7968153, 7377959];

  public generateVouchers(
    purchaseOrderNumber: number,
    orderItem: string
  ): Observable<string> {
    const body = {
      orderNumber: String(purchaseOrderNumber),
      position: orderItem,
    };

    return this.httpClient.post(
      `${environment.http.equipmentBaseUrl}/vouchers/generate/order/${purchaseOrderNumber}/position/${orderItem}`,
      body,
      {
        headers: { 'content-type': 'application/json' },
        responseType: 'text',
      }
    );
  }

  public linkVouchersWithSerialNumber(
    itemsToLink: LinkItem[]
  ): Observable<unknown> {
    const body: SerialLinkItems = {
      items: itemsToLink,
    };

    return this.httpClient.post(
      `${environment.http.equipmentBaseUrl}/vouchers/link-with-serial-numbers`,
      body
    );
  }

  public getVoucherType(materialNumber: number): string {
    return this.VOUCHERS_01_MATERIAL.includes(materialNumber)
      ? 'modulation'
      : 'on-off';
  }

  public getGeneratedVouchers(orderNumber: string, position: string) {
    return this.httpClient
      .get(
        `${environment.http.equipmentBaseUrl}/vouchers/generate/order/${orderNumber}/position/${position}`,
        {
          headers: { 'content-type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(
        map((csv) => {
          return this.validateVoucherCSV(csv);
        }),
        catchError(() => of(''))
      );
  }

  // Just return empty string when the csv contains just the header row
  private validateVoucherCSV(csv: string): string {
    return csv && csv.replace(/\r+/g, '').split('\n').filter(Boolean).length > 1
      ? csv
      : '';
  }
}

export interface SerialLinkItems {
  items: LinkItem[];
}

export interface LinkItem {
  code: string;
  serialNr: string;
}
