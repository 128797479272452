import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { SnackbarService } from '../../../../services/snackbar.service';
import { OrderItem } from '../../services/order.service';
import { VoucherApiService } from '../../services/voucher-api.service';

@Component({
  selector: 'app-voucher-dialog',
  templateUrl: './voucher-dialog.component.html',
  styleUrls: ['./voucher-dialog.component.scss'],
})
export class VoucherDialogComponent {
  status: 'Default' | 'Pending' = 'Default';

  constructor(
    public dialogRef: MatDialogRef<VoucherDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: GenerateVoucherData,
    public voucherService: VoucherApiService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {}

  generateVouchers() {
    this.status = 'Pending';
    this.voucherService
      .generateVouchers(
        this.data.purchaseOrderNumber,
        this.data.orderItem.PO_ITEM
      )
      .subscribe(
        (data) => {
          const csv = new Blob([data], { type: 'text/csv' });
          FileSaver.saveAs(csv, 'VoucherList');
          this.close(this.data.purchaseOrderNumber);
        },
        (error) => {
          this.close();
          this.snackbarService.showSnackbar(this.getErrorText(error), false);
        }
      );
  }

  close(orderNumber?: number) {
    this.dialogRef.close(orderNumber);
  }

  private getErrorText(error: HttpErrorResponse): string {
    return error.status === 404
      ? this.translateService.instant('snackbar.error.material-not-found', {
          value: this.data.orderItem.MATERIAL,
        })
      : this.translateService.instant('snackbar.error.general');
  }
}

interface GenerateVoucherData {
  purchaseOrderNumber: number;
  orderItem: OrderItem;
}
