import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { PendingService } from './pending.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private readonly apiHost: string;
  constructor(
    private auth: AuthService,
    private pendingService: PendingService
  ) {
    this.apiHost = new URL(environment.apiUrl).host;
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.pendingService.setPendingStatus(true, req.url);

    if (!req.url.includes(this.apiHost)) {
      return next.handle(req).pipe(
        // @ts-ignore
        map<HttpEvent<unknown>, never>((event: HttpEvent<unknown>) => {
          this.pendingService.setPendingStatus(false, req.url);
          return event;
        })
      );
    }

    return this.auth.csrfToken$.pipe(
      first((val) => val !== undefined),
      switchMap((value) =>
        next
          .handle(
            req.clone({
              headers: req.headers.set('Authorization', `CSRF ${value}`),
            })
          )
          .pipe(
            // @ts-ignore
            map<HttpEvent<unknown>, never>((evt: HttpEvent<unknown>) => {
              if (evt instanceof HttpResponse) {
                this.pendingService.setPendingStatus(false, req.url);
              }
              return evt;
            })
          )
          .pipe(
            catchError((e) => {
              if (e instanceof HttpErrorResponse && e.status === 401) {
                // refresh CSRF token and retry active request if fetching of token succeed
                return this.auth
                  .fetchNewCsrfToken()
                  .pipe(switchMap(() => this.intercept(req, next)));
              }
              this.pendingService.setPendingStatus(false, req.url);
              throw e;
            })
          )
      )
    );
  }
}
