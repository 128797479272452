<mat-card class="h-100 d-flex flex-column">
  <!-- Serial numbers link -->
  <h2>{{ "home.import-card.title-status" | translate }}</h2>
  <mat-card-content class="flex-grow-1">
    <!--  Please select a file  >-->
    <p>
      {{ "home.import-card.content" | translate }}
    </p>
  </mat-card-content>
  <mat-card-actions class="flex-shrink-0" align="end">
    <button type="button" mat-button (click)="fileInput.click()">
      <!--  Choose file  -->
      {{ "home.import-card.button" | translate }}
    </button>
    <input
      hidden
      accept="text/csv"
      (change)="handleFileInput(fileInput.files)"
      #fileInput
      (click)="fileInput.value = ''"
      type="file"
    />
  </mat-card-actions>
</mat-card>
