import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService) {}

  canActivate(): Observable<boolean> {
    if (this.auth.hasValidCsrfToken()) {
      return of(true);
    }
    // start csrf token authentication process
    return this.auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
  }
}
