import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { LicenceDialogComponent } from '../licence-dialog/licence-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public version: string = environment.version;

  constructor(private dialog: MatDialog) {}

  public openViessmannWebsite(): void {
    window.open('https://www.viessmann.de/');
  }

  public showLicences(): void {
    this.dialog.open(LicenceDialogComponent);
  }
}
