import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { VoucherApiService } from './voucher-api.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private httpClient: HttpClient,
    private voucherService: VoucherApiService
  ) {}

  public getOrderDetails(orderNumber: string): Observable<PurchaseOrder> {
    return this.httpClient
      .get<PurchaseOrder>(
        `${environment.http.equipmentBaseUrl}/purchase-order/${orderNumber}`
      )
      .pipe(
        switchMap((order) => {
          return combineLatest([
            of(order),
            forkJoin(
              order.POITEM.item.map((item) =>
                this.voucherService.getGeneratedVouchers(
                  orderNumber,
                  item.PO_ITEM
                )
              )
            ),
          ]);
        }),
        map(([order, voucherList]) => {
          return {
            ...order,
            POITEM: {
              ...order.POITEM,
              item: order.POITEM.item.map((item, i) => {
                return {
                  ...item,
                  csv: voucherList[i],
                };
              }),
            },
          };
        })
      );
  }
}

export interface PurchaseOrder {
  POHEADER: OrderHeader;
  POITEM: {
    item: OrderItem[];
  };
}

export interface OrderHeader {
  PO_NUMBER: number;
  CREAT_DATE: string;
  VENDOR: string;
  LANGU_ISO: string;
}

export interface OrderItem {
  PO_ITEM: string;
  SHORT_TEXT: string;
  MATERIAL: number;
  QUANTITY: string;
  csv?: string;
}
