<mat-progress-bar
  mode="query"
  *ngIf="pendingService.getPendingStatus() | async"
></mat-progress-bar>
<div class="container-fluid py-5">
  <div class="container">
    <div class="row d-flex">
      <div class="col-lg-6 col-sm-12">
        <app-order-search
          (emitOPurchaseOrder)="getPurchaseOrder($event)"
        ></app-order-search>
      </div>
      <div class="col-lg-6 col-sm-12 mt-lg-0 mt-5">
        <app-voucher-code-import></app-voucher-code-import>
      </div>
    </div>
    <div class="row d-flex mt-5" *ngIf="purchaseOrder">
      <div class="col-12">
        <app-order-table
          [purchaseOrder]="purchaseOrder"
          (openVoucherDialog)="openVoucherDialog($event)"
          (downloadFile)="downloadVoucherCSV($event)"
        ></app-order-table>
      </div>
    </div>
  </div>
</div>
