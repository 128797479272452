import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { TranslationConfigService } from './services/translation-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: AuthService,
    translationConfig: TranslationConfigService
  ) {
    translationConfig.setLanguage();
  }

  ngOnInit(): void {
    this.auth.init();
  }
}
