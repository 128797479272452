import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { PendingService } from '../../services/pending.service';
import { SnackbarService } from '../../services/snackbar.service';
import { VoucherDialogComponent } from './components/voucher-dialog/voucher-dialog.component';
import {
  OrderItem,
  OrderService,
  PurchaseOrder,
} from './services/order.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  purchaseOrder!: PurchaseOrder;

  constructor(
    private orderService: OrderService,
    public pendingService: PendingService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {}

  getPurchaseOrder(orderNumber: string) {
    this.orderService.getOrderDetails(orderNumber).subscribe({
      next: (order) => (this.purchaseOrder = order),
      error: (err) =>
        this.snackbarService.showSnackbar(this.getErrorText(err), false),
    });
  }

  openVoucherDialog(orderItem: OrderItem) {
    const dialogRef = this.dialog.open(VoucherDialogComponent, {
      data: {
        purchaseOrderNumber: this.purchaseOrder?.POHEADER.PO_NUMBER,
        orderItem,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe({
      next: (orderNumber) => {
        if (orderNumber) {
          this.getPurchaseOrder(orderNumber);
        }
      },
    });
  }

  downloadVoucherCSV(orderItem: OrderItem) {
    const csv = new Blob([orderItem?.csv || ''], { type: 'text/csv' });
    FileSaver.saveAs(csv, 'VoucherList');
  }

  private getErrorText(error: HttpErrorResponse): string {
    return error.status === 404
      ? this.translateService.instant('snackbar.error.order-not-found')
      : this.translateService.instant('snackbar.error.general');
  }
}
