import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PendingService } from '../../../../services/pending.service';
import { OrderItem, PurchaseOrder } from '../../services/order.service';
import { VoucherApiService } from '../../services/voucher-api.service';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent {
  @Input() purchaseOrder!: PurchaseOrder;

  @Output() openVoucherDialog = new EventEmitter<OrderItem>();

  @Output() downloadFile = new EventEmitter<OrderItem>();

  constructor(
    public voucherService: VoucherApiService,
    public pendingService: PendingService
  ) {}

  selectOrderItem(orderItem: OrderItem): void {
    this.openVoucherDialog.emit(orderItem);
  }

  download(orderItem: OrderItem): void {
    this.downloadFile.emit(orderItem);
  }
}
