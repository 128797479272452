<div class="mat-elevation-z1">
  <div class="row">
    <div class="col-12 d-flex justify-content-center p-3">
      <b
        >{{ "home.order-table.title" | translate }}
        {{ purchaseOrder?.POHEADER?.PO_NUMBER }}</b
      >
    </div>
  </div>
  <mat-divider></mat-divider>
  <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport">
    <div class="row align-items-center p-3">
      <span class="col-2 d-flex justify-content-center heading">
        {{ "home.order-table.header.position-number" | translate }}
      </span>
      <span class="col-3 d-flex justify-content-center heading">
        {{ "home.order-table.header.type" | translate }}
      </span>
      <span class="col-3 d-flex justify-content-center heading">
        {{ "home.order-table.header.quantity" | translate }}
      </span>
    </div>
    <mat-divider></mat-divider>
    <div
      *cdkVirtualFor="let item of purchaseOrder.POITEM.item; let i = index"
      class="highlight"
    >
      <div class="row align-items-center p-2">
        <span class="col-2 d-flex justify-content-center">
          {{ item.PO_ITEM }}
        </span>
        <span class="col-3 d-flex justify-content-center">
          {{ item.MATERIAL }}
          {{
            "home.order-table.voucher-type." +
              voucherService.getVoucherType(item.MATERIAL) | translate
          }}
        </span>
        <span class="col-3 d-flex justify-content-center">
          {{ item.QUANTITY | number }}
        </span>
        <span class="col-4 d-flex justify-content-center">
          <button
            mat-button
            color="primary"
            (click)="selectOrderItem(item)"
            *ngIf="!item.csv"
            [disabled]="pendingService.getPendingStatus() | async"
          >
            {{ "home.order-table.generate-button" | translate }}
          </button>

          <button
            mat-button
            color="primary"
            (click)="download(item)"
            *ngIf="item.csv"
            [disabled]="pendingService.getPendingStatus() | async"
          >
            {{ "home.order-table.download-button" | translate }}
          </button>
        </span>
      </div>
      <mat-divider
        *ngIf="i !== purchaseOrder.POITEM.item.length - 1"
      ></mat-divider>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
