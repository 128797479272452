<!-- Validation error -->
<h2 mat-dialog-title>{{ "error-dialog.title" | translate }}</h2>
<ng-container *ngIf="data && !data.invalidVoucher; else invalidVoucherTemplate">
  <div mat-dialog-content>
    <!-- File does not match requirements -->
    <p
      [innerHTML]="'error-dialog.text' | translate: { fileName: data.fileName }"
    ></p>
    <!-- Please check the following: -->
    <p>{{ "error-dialog.validation-title" | translate }}</p>
    <hr />
    <!-- The file must be a valid CSV file. -->
    <p>{{ "error-dialog.error-message.valid-csv" | translate }}</p>
    <hr />
    <!-- File must have following columns: PO Nr.,Pos Nr.,Activation Code,Type,S/N -->
    <p [innerHTML]="'error-dialog.error-message.header' | translate"></p>
    <hr />
    <!-- All columns must be completely filled. -->
    <p>{{ "error-dialog.error-message.fully-filled" | translate }}</p>
    <hr />
    <!-- Only voucher types 7968153, 7949932, 7955033 and 7955034  -->
    <p>{{ "error-dialog.error-message.allowed-vouchers" | translate }}</p>
  </div>
</ng-container>
<ng-template #invalidVoucherTemplate>
  <p>
    <!-- At least one of the uploaded vouchers is invalid  -->
    {{ "error-dialog.error-message.invalid-voucher" | translate }}
  </p>
</ng-template>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="true">
    {{ "error-dialog.close-button" | translate }}
  </button>
</div>
