import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { OrderSearchComponent } from './components/order-search/order-search.component';
import { OrderTableComponent } from './components/order-table/order-table.component';
import { VoucherCodeImportComponent } from './components/voucher-code-import/voucher-code-import.component';
import { VoucherDialogComponent } from './components/voucher-dialog/voucher-dialog.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { VoucherApiService } from './services/voucher-api.service';

const materialModules = [
  MatCardModule,
  MatTabsModule,
  MatFormFieldModule,
  MatInputModule,
  ReactiveFormsModule,
  MatSelectModule,
  FormsModule,
  MatButtonModule,
  MatTableModule,
  MatDividerModule,
  MatIconModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatDialogModule,
];

@NgModule({
  declarations: [
    HomeComponent,
    OrderSearchComponent,
    OrderTableComponent,
    VoucherDialogComponent,
    VoucherCodeImportComponent,
    ErrorDialogComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    TranslateModule,
    ScrollingModule,
    ...materialModules,
  ],
  entryComponents: [VoucherDialogComponent],
  providers: [VoucherApiService],
})
export class HomeModule {}
