import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss'],
})
export class OrderSearchComponent {
  @Output() emitOPurchaseOrder = new EventEmitter<string>();

  orderNumber = new FormControl('', [Validators.required]);
}
