<div class="row">
  <div class="col-12">
    <mat-card>
      <h2>{{ "home.card.title-status" | translate }}</h2>
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>{{
          "home.input.purchase-order-number" | translate
        }}</mat-label>
        <input
          #orderNumberInput
          matInput
          [formControl]="orderNumber"
          required
          (keydown.enter)="emitOPurchaseOrder.emit(orderNumberInput.value)"
        />
        <mat-error *ngIf="orderNumber.invalid">{{
          "home.error.mandatory-input" | translate
        }}</mat-error>
      </mat-form-field>
      <mat-card-actions align="end">
        <button
          [disabled]="!orderNumberInput.value"
          mat-button
          (click)="emitOPurchaseOrder.emit(orderNumberInput.value)"
        >
          {{ "home.button.search" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
