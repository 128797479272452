import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(public snackbar: MatSnackBar) {}

  public showSnackbar(
    message: string,
    success: boolean,
    action?: string,
    duration?: number
  ) {
    this.snackbar.open(message, action ? action : '', {
      duration: duration ? duration : 3000,
      panelClass: [success ? 'success-snackbar' : 'error-snackbar'],
    });
  }
}
