import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LicenceDialogComponent } from './components/licence-dialog/licence-dialog.component';
import { HomeModule } from './modules/home/home.module';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { TranslationConfigService } from './services/translation-config.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.localization.baseUrl}phraseapp-proxy/projects/${environment.localization.projectId}/locales/`,
    '/download?file_format=i18next'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LicenceDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HomeModule,
    GlobalHeaderModule,
    GlobalHeaderModule.forRoot({
      baseUrl: environment.auth.baseUrl,
      partnerPortalBaseUrl: environment.auth.registerBaseUrl,
      appId: environment.auth.appId,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    TranslationConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
