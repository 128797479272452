import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PendingService {
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  getPendingStatus(): Observable<boolean> {
    return this.loadingSubject;
  }

  setPendingStatus(isPending: boolean, url: string) {
    if (isPending) {
      this.loadingMap.set(url, isPending);
      this.loadingSubject.next(true);
    } else if (!isPending && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }

    if (this.loadingMap.size === 0) {
      this.loadingSubject.next(false);
    }
  }
}
