<div class="container py-3">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <h3>
        <b>{{ "voucher-dialog.title" | translate }}</b>
      </h3>
    </div>
    <mat-divider class="pb-3"></mat-divider>
  </div>
  <div class="row p-2">
    <div class="col-6">
      <b>{{ "voucher-dialog.order-number" | translate }}</b>
    </div>
    <div class="col-6">
      {{ data.purchaseOrderNumber }}
    </div>
  </div>
  <div class="row p-2">
    <div class="col-6">
      <b>{{ "voucher-dialog.position" | translate }}</b>
    </div>
    <div class="col-6">
      {{ data.orderItem.PO_ITEM }}
    </div>
  </div>
  <div class="row p-2">
    <div class="col-6">
      <b>{{ "voucher-dialog.quantity" | translate }}</b>
    </div>
    <div class="col-6">
      {{ data.orderItem.QUANTITY | number }}
    </div>
  </div>
  <div class="row p-2 d-flex align-items-center">
    <div class="col-6">
      <b>{{ "voucher-dialog.voucher-type.title" | translate }}</b>
    </div>
    <div class="col-6">
      {{
        "voucher-dialog.voucher-type." +
          voucherService.getVoucherType(data.orderItem.MATERIAL) | translate
      }}
    </div>
  </div>
  <mat-divider class="my-3"></mat-divider>
  <div class="d-flex justify-content-end">
    <button
      mat-button
      (click)="close()"
      class="me-2"
      [disabled]="status === 'Pending'"
    >
      {{ "voucher-dialog.button.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="generateVouchers()"
      [disabled]="status === 'Pending'"
    >
      {{ "voucher-dialog.button.ok" | translate }}
    </button>
  </div>
</div>
