import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationConfigService {
  readonly defaultLanguage = 'en-US';
  readonly supportedLanguages = ['de-DE', 'en-US', 'pl-PL'];

  constructor(private translate: TranslateService) {}

  setLanguage(language?: string): void {
    let supportedLanguage = this.findSupportedLanguage(
      language ? language : navigator.language
    );

    if (!supportedLanguage) {
      supportedLanguage = this.defaultLanguage;
    }

    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.use(supportedLanguage);
  }

  private findSupportedLanguage(language: string): string | undefined {
    let supportedLanguage = this.supportedLanguages.find(
      (l) => l.toLocaleLowerCase() === language.toLocaleLowerCase()
    );

    if (supportedLanguage) {
      return supportedLanguage;
    }

    supportedLanguage = this.supportedLanguages.find((l) =>
      l
        .toLocaleLowerCase()
        .startsWith(language.split('-')[0].toLocaleLowerCase())
    );

    return supportedLanguage;
  }
}
