<!--Open-Source Licences-->
<h2>{{ "licence-dialog.open-source" | translate }}</h2>
<ul>
  <li>
    <h3>
      Angular:
      <a href="https://angular.io/" target="_blank">https://angular.io/</a>
    </h3>
  </li>
  <li>
    <h3>
      Node.js:
      <a href="https://nodejs.org/en/about/" target="_blank"
        >https://nodejs.org/en/about/</a
      >
    </h3>
  </li>
  <li>
    <h3>
      TypeScript:
      <a href="https://www.typescriptlang.org/" target="_blank"
        >https://www.typescriptlang.org/</a
      >
    </h3>
  </li>
  <li>
    <h3>
      Jenkins:
      <a href="https://jenkins.io/" target="_blank">https://jenkins.io/</a>
    </h3>
  </li>
  <li>
    <h3>
      Git:
      <a href="https://git-scm.com/" target="_blank">https://git-scm.com/</a>
    </h3>
  </li>
  <li>
    <h3>
      Angular Material:
      <a href="https://material.angular.io/" target="_blank"
        >https://material.angular.io/</a
      >
    </h3>
  </li>
</ul>
